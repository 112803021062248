const TOKEN_KEY = 'token';

export const login = (item) => {
    localStorage.setItem(TOKEN_KEY,JSON.stringify(item));
}
export const validar = (item, text) => {
    localStorage.setItem(text,JSON.stringify(item));
}
export const getvalidar = (item) => {
    return localStorage.getItem(item);
}
export const token = (item) => {
    localStorage.setItem('tokenfinal', item);
}
export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
}
export const getLogin = () => {
    return localStorage.getItem(TOKEN_KEY);
}
export const getoken = () => {
    return localStorage.getItem('tokenfinal');
}
export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }

    return false;
}