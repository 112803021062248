import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import API from '../../services/config';
import Swal from "sweetalert2";
import {login, token} from '../../action/Auth';
import {
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import queryString from 'query-string';
const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [formLogin, setFormLogin] = useState({
    token:'',
    id:'',
    email:'',
    password:''
  });
  const [isLoanding, setIsLoading] = React.useState(false);
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn  = async  event =>  {
    event.preventDefault();
    setIsLoading(true);
    console.log(formState)
    if (formState.values.email === '') {
      Swal.fire("Lo siento!", 'Email no válido', "error");
    } else if (formState.values.password === '') {
      Swal.fire("Lo siento!", 'Password no válido', "error");
    } else {
      try {
        const data={
            email:formState.values.email,
            password: formState.values.password
        }
        const finalok = await API.post('auth/login', data);
        login(finalok.data.user);
        token(finalok.data.token)
        setIsLoading(false);
        history.push('/dashboard');
      } catch (err) {
          setIsLoading(false);
          console.log(err)
          Swal.fire("Lo siento!", 'credencial invalida', "error");
      } 
    }
  };
  const handleSignInget  = async  event =>  {
    console.log(event) 
    setIsLoading(true);
    if (event.email === undefined) {
      Swal.fire("Lo siento!", 'Email no válido', "error");
    } else if (event.password === undefined) {
      Swal.fire("Lo siento!", 'Password no válido', "error");
    }else if (event.token === undefined) {
      Swal.fire("Lo siento!", 'Token invalido', "error");
    } else {
      try {
        const finalok = await API.post('auth/token/login', event);
        login(finalok.data.user);
        token(finalok.data.token)
        setIsLoading(false);
        history.push('/dashboard');
      } catch (err) {
          setIsLoading(false);
          console.log(err)
          Swal.fire("Lo siento!", 'credencial invalida', "error");
      } 
    }
  };
  useEffect(() => {
    var query = window.location.search.substring(1);
    const values = queryString.parse(query)
    const data = {
      token:values.to,
      id:values.id,
      email:values.em,
      password:values.pas
    }
    console.log(values.length)
    if (data.token !== undefined) {
      handleSignInget(data);
    }
    
  }, []);
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
    if (isLoanding) {
      return (
        <div className="cargando">
          <img
            alt="Logo"
            src="/images/logos/Cube-1s-200px.gif"
          />
          <Typography
                  className={classes.name}
                  variant="body1"
                >
                 Cargando...
          </Typography>
        </div>
      );
    }else{
      return (
        <div className={classes.root}>
          <Grid
            className={classes.grid}
            container
          >
            <Grid
              className={classes.quoteContainer}
              item
              lg={5}
            >
              <div className={classes.quote}>
                <div className={classes.quoteInner}>
                  <Typography
                    className={classes.quoteText}
                    variant="h1"
                  >
                    Sistema de administración de requerimientos de la infractuctura técnologica
                  </Typography>
                  <div className={classes.person}>
                    <Typography
                      className={classes.name}
                      variant="body1"
                    >
                     Acresar consultores
                    </Typography>
                    <img
                      className="ajusticiar"
                      alt="Logo"
                      src="/images/logos/acresar.png"
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              className={classes.content}
              item
              lg={7}
              xs={12}
            >
              <div className={classes.content}>
                <div className={classes.contentBody}>
                  <form
                    className={classes.form}
                    onSubmit={handleSignIn}
                  >
                    <Typography
                      className={classes.title}
                      variant="h2"
                    >
                      Inciar sesión
                    </Typography>
                    
                    
                    <TextField
                      className={classes.textField}
                      error={hasError('email')}
                      fullWidth
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      label="Email address"
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={hasError('password')}
                      fullWidth
                      helperText={
                        hasError('password') ? formState.errors.password[0] : null
                      }
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.password || ''}
                      variant="outlined"
                    />
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Iniciar
                    </Button>
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
  
  
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
