import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../action/Auth';

const PublicRoute = ({layout: Layout, component: Component, restricted, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            isLogin() && restricted ?
                <Redirect to="/dashboard" />
            : <Layout><Component {...props} /></Layout>
        )} />
    );
};

export default PublicRoute;