import React, { useState, useEffect } from 'react';
import { Link as RouterLink , useHistory} from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import API from '../../../services/config';
import { Grid, Input, TextareaAutosize , FormControlLabel, Checkbox} from '@material-ui/core';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import {getLogin} from '../../../action/Auth';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  label:{
    width:'100%',
  }
}));
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const NewInventory = () => {
  const cerrar = useHistory();
  const classes = useStyles();
  const oficina = [];
  const contact = [];
  const users =JSON.parse(getLogin());
  const [state, setState] = useState({
    pc: false,
    port: false,
    mouse: false,
    tecl: false,
    monitor: false,
  });
  const { register, control, handleSubmit , watch, errors} = useForm();
  const onSubmit = async data => {
    try {
      Swal.fire({
        title: 'loading...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 4000,
      });
      const datafinal ={
        idofi:data.oficina.value,
        description:data.textarea,
        idusers:data.responsable.value,
        pc:state.pc,
        port:state.port,
        mouse:state.mouse,
        tecl:state.tecl,
        mont:state.monitor,
        state:0,
        pcfinal:{
          refe: data.referencia,
          marca: data.marca,
          modelo:data.modelo,
          description: data.pcdecrip
        },
        portfinal:{
          refe: data.portreferencia,
          marca: data.portmarca,
          modelo:data.portmodelo,
          description: data.portdecrip
        },
        mousefinal:{
          refe: data.moreferencia,
          marca: data.momarca,
          modelo:data.momodelo,
          description: data.modecrip
        },
        tcfinal:{
          refe: data.tcreferencia,
          marca: data.tcmarca,
          modelo:data.tcmodelo,
          description: data.tcdecrip
        },
        monfinal:{
          refe: data.mnreferencia,
          marca: data.mnmarca,
          modelo:data.mnmodelo,
          description: data.mndecrip
        }
      }
      const finalok = await API.post('create/inventario', datafinal);
      console.log(finalok.data);
      if (finalok.data.length !==0) {
        Swal.fire({ 
          title: 'Corecto!',
          type: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        cerrar.push('/inventory');
      }else{
        Swal.fire("Lo siento!", 'Algo salio mal', "error");
      }
      
    } catch (err) {
      if (err.code !== undefined) {
        Swal.fire("Lo siento!", 'El Responsable ya tiene asignado un inventario', "error");
      } else {
        Swal.fire("Lo siento!", 'Algo salio mal', "error");
      }
        console.log(err);
    }  
  };
  const handleSignIn  = async() =>  {
      try {
        const finalok = await API.get('configuracion/usuarios');
        return finalok.data;
      } catch (err) {
          console.log(err);
      } 
  }
  const oficinalfinal  = async() =>  {
    try {
      const finalok = await API.get('oficina');
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  useEffect(() => {
    handleSignIn().then((data)=>{
      data.find(element => {
        contact.push({
          value:element.id,
          label:element.name
        })
      });
    });
  }, []);
  useEffect(() => {
    oficinalfinal().then((data)=>{
      data.find(element => {
        oficina.push({
          value:element.id,
          label:element.name
        })
      });
    });
  }, []);
  return (
      <div className={classes.root}>
        <div className={classes.content}>
        <div className={classes.root}>
            <form className="formfinal" onSubmit={handleSubmit(onSubmit)}>
                <Grid
                container
                spacing={2}
                >
                  <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                          <label className="titulofinal">Nuevo inventario</label>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <label className={classes.label}>Selecciona la oficina</label>
                    <Controller
                      name="oficina"
                      as={Select}
                      options={oficina}
                      control={control}
                      defaultValue=""
                      required
                      />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <label className={classes.label}>Selecciona el responsable</label>
                    <Controller
                      name="responsable"
                      as={Select}
                      options={contact}
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      required
                      />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <label className={classes.label}>Selecciona los equipo asignados</label>
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.pc} onChange={handleChange} name="pc" />}
                      label="Computador de escritorio"
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.port} onChange={handleChange} name="port" />}
                      label="Portatil"
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.mouse} onChange={handleChange} name="mouse" />}
                      label="Mouse"
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.tecl} onChange={handleChange} name="tecl" />}
                      label="Teclado"
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.monitor} onChange={handleChange} name="monitor" />}
                      label="Monitor"
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <label className={classes.label}>Descripcion inventario</label>
                    <TextareaAutosize 
                    name="textarea"
                    aria-label="minimum height" 
                    rowsMin={3} placeholder="Minimum 3 rows" 
                    ref={register({ required: true, maxLength: 250 })}/>
                    {errors.textarea && <p>This field is required</p>}
                  </Grid>
                    {
                      state.pc === true ?
                      <Grid
                      container
                      className="ajutscont"
                      spacing={2}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                          <label className={classes.label}>Computador de escritorio</label>
                          </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>No. referencia</label>
                          <input
                              name="referencia"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.referencia && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Marca</label>
                          <input
                              name="marca"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.marca && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Modelo</label>
                          <input
                              name="modelo"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.modelo && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                        <label className={classes.label}>Descripcion</label>
                        <TextareaAutosize 
                        name="pcdecrip"
                        aria-label="minimum height" 
                        rowsMin={3} placeholder="Minimum 3 rows" 
                        ref={register({ required: true, maxLength: 250 })}/>
                        {errors.pcdecrip && <p>This field is required</p>}
                        </Grid>
                      </Grid>:null
                    }
                    {
                      state.port === true ?
                      <Grid
                      container
                      className="ajutscont"
                      spacing={2}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                          <label className={classes.label}>Portatil</label>
                          </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>No. referencia</label>
                          <input
                              name="portreferencia"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.portreferencia && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Marca</label>
                          <input
                              name="portmarca"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.portmarca && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Modelo</label>
                          <input
                              name="portmodelo"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.portmodelo && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                        <label className={classes.label}>Descripcion</label>
                        <TextareaAutosize 
                        name="portdecrip"
                        aria-label="minimum height" 
                        rowsMin={3} placeholder="Minimum 3 rows" 
                        ref={register({ required: true, maxLength: 250 })}/>
                        {errors.portdecrip && <p>This field is required</p>}
                        </Grid>
                      </Grid>:null
                    }
                    {
                      state.mouse === true ?
                      <Grid
                      container
                      className="ajutscont"
                      spacing={2}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                          <label className={classes.label}>Mouse</label>
                          </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>No. referencia</label>
                          <input
                              name="moreferencia"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.moreferencia && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Marca</label>
                          <input
                              name="momarca"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.momarca && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Modelo</label>
                          <input
                              name="momodelo"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.momodelo && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                        <label className={classes.label}>Descripcion</label>
                        <TextareaAutosize 
                        name="modecrip"
                        aria-label="minimum height" 
                        rowsMin={3} placeholder="Minimum 3 rows" 
                        ref={register({ required: true, maxLength: 250 })}/>
                        {errors.modecrip && <p>This field is required</p>}
                        </Grid>
                      </Grid>:null
                    }
                    {
                      state.tecl === true ?
                      <Grid
                      container
                      className="ajutscont"
                      spacing={2}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                          <label className={classes.label}>Teclado</label>
                          </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>No. referencia</label>
                          <input
                              name="tcreferencia"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.tcreferencia && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Marca</label>
                          <input
                              name="tcmarca"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.tcmarca && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Modelo</label>
                          <input
                              name="tcmodelo"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.tcmodelo && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                        <label className={classes.label}>Descripcion</label>
                        <TextareaAutosize 
                        name="tcdecrip"
                        aria-label="minimum height" 
                        rowsMin={3} placeholder="Minimum 3 rows" 
                        ref={register({ required: true, maxLength: 250 })}/>
                        {errors.tcdecrip && <p>This field is required</p>}
                        </Grid>
                      </Grid>:null
                    }
                    {
                      state.monitor === true ?
                      <Grid
                      container
                      className="ajutscont"
                      spacing={2}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                          <label className={classes.label}>Monitor</label>
                          </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>No. referencia</label>
                          <input
                              name="mnreferencia"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.mnreferencia && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Marca</label>
                          <input
                              name="mnmarca"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.mnmarca && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xl={4}
                          xs={12}
                        >
                        <label className={classes.label}>Modelo</label>
                          <input
                              name="mnmodelo"
                              ref={register({ required: true, maxLength: 10 })}
                          />
                          {errors.mnmodelo && <p>This field is required</p>}
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                        <label className={classes.label}>Descripcion</label>
                        <TextareaAutosize 
                        name="mndecrip"
                        aria-label="minimum height" 
                        rowsMin={3} placeholder="Minimum 3 rows" 
                        ref={register({ required: true, maxLength: 250 })}/>
                        {errors.mndecrip && <p>This field is required</p>}
                        </Grid>
                      </Grid>:null
                    }
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}
                  >
                  <input type="submit" />
                  </Grid>
                </Grid>
            </form>
        </div>
        </div>
      </div>
  );
};

export default NewInventory;
