import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import API from '../../services/config';
import { UsersToolbar, UsersTable } from './components';
import AuthContext from "../../services/AuthContext";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const InventoryList = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const handleSignIn  = async() =>  {
      try {
        const finalok = await API.get('inventario');
        //setUsersfinal({ array:finalok.data});
        console.log(finalok)
        return finalok.data;
      } catch (err) {
          console.log(err);
      } 
  }
  useEffect(() => {
    handleSignIn().then((data)=>setUsers(data));
  }, []);
  return (
      <div className={classes.root}>
        <UsersToolbar />
        <div className={classes.content}>
          <UsersTable users={users}/>
        </div>
      </div>
  );
};

export default InventoryList;
