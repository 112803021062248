import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import API from '../../services/config';
import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  LatestSales,
  UsersByDevice,
  LatestProducts,
  LatestOrders
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState([]);
  const [cerrado, setCerrado] = useState([]);
  const [horas, setHoras] = useState([]);
  const [order, setOrder] = useState([]);
  const [agrupar, setAgrupar] = useState([]);
  
  const handleSignIn  = async() =>  {
    try {
      const finalok = await API.get('active');
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  const orderfinal  = async() =>  {
    try {
      const finalok = await API.get('ultimos');
      //setUsersfinal({ array:finalok.data});
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  const agruparfinal  = async() =>  {
    try {
      const finalok = await API.get('agrupar');
      //setUsersfinal({ array:finalok.data});
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  const totalfinal = async() =>  {
    try {
      const finalok = await API.get('total');
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  const horasfinal = async() =>  {
    try {
      const finalok = await API.get('horas');
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  const cerradofinal  = async() =>  {
    try {
      const finalok = await API.get('cerrado');
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  useEffect(() => {
    handleSignIn().then((data)=>setUsers(data));
  }, []);
  useEffect(() => {
    totalfinal().then((data)=> setTotal(data));
    cerradofinal().then((data)=> setCerrado(data));
    agruparfinal().then((data)=> setAgrupar(data));
    horasfinal().then((data)=> setHoras(data));
    orderfinal().then((data)=> setOrder(data));
  }, []);
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Budget total={total} users={users}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers total={total} cerrado={cerrado}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress total={total.length} users={cerrado.length}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit total={total.length} />
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          xl={9}
          xs={12}
        >
          <LatestSales total={total} horas={horas}/>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xl={3}
          xs={12}
        >
          <UsersByDevice total={total.length} agrupar={agrupar} />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <LatestOrders order={order}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
