import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import API from '../../services/config';
import { UsersToolbar, UsersTable } from './components';
import AuthContext from "../../services/AuthContext";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [usersfinal, setUsersfinal] = useState([]);
  const handleSignIn  = async() =>  {
      try {
        const finalok = await API.get('requerimientos');
        //setUsersfinal({ array:finalok.data});
        return finalok.data;
      } catch (err) {
          console.log(err);
      } 
  }
  const authContext = useMemo(() => ({
    signIn: async () => {
      handleSignIn().then((data)=>setUsers(data));
    },
    signOut: async () => {
      handleSignIn().then((data)=>setUsers(data));
    },
  }));
  useEffect(() => {
    handleSignIn().then((data)=>setUsers(data));
  }, []);
  return (
    <AuthContext.Provider value={authContext}>
      <div className={classes.root}>
        <UsersToolbar />
        <div className={classes.content}>
          <UsersTable users={users}/>
        </div>
      </div>
    </AuthContext.Provider>
  );
};

export default UserList;
