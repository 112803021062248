import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import API from '../../../../services/config';
import {getLogin} from '../../../../action/Auth';
import AuthContext from "../../../../services/AuthContext";
import validate from 'validate.js';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
import BlockSharpIcon from '@material-ui/icons/BlockSharp';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText
} from '@material-ui/core';
import Swal from "sweetalert2";
import Modal from '@material-ui/core/Modal';
const schema = {
  response: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 250
    }
  }
};
const schemauno = {
  escala: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 250
    }
  },
  prioridad: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 250
    }
  },
  nivel: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 250
    }
  }
};
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
const useStyles = makeStyles(theme => ({
  content: {
    width:'100%',
    padding: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    margin: theme.spacing(1),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    width:'100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom:5,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth:'100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const UsersTable = props => {
  const { className, users, ...rest } = props;
  const date = new Date();
  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);
  const usersfinal =JSON.parse(getLogin());
  const [idpe, setIdpe] = useState(0);
  const hasError = field =>
  formState.touched[field] && formState.errors[field] ? true : false;
  const [formScala, setFormScala] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  useEffect(() => {
    const errors = validate(formScala.values, schemauno);
    setFormScala(formScala => ({
      ...formScala,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formScala.values]);
  const hasErroruno = field =>
  formScala.touched[field] && formScala.errors[field] ? true : false;
  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
  const handleChangeuno = event => {
    event.persist();
    setFormScala(formScala => ({
      ...formScala,
      values: {
        ...formScala.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formScala.touched,
        [event.target.name]: true
      }
    }));
  };
  const handleSignIn  = async  event =>  {
    event.preventDefault();
    console.log(formState)
    if (formState.values.response === '') {
      Swal.fire("Lo siento!", 'Debes descibir el trabajo realizado', "error");
    } else if (idpe === 0) {
      Swal.fire("Lo siento!", 'Debes seleccionar un requerimiento', "error");
    }  else {
      try {
        const data={
            dateres: date,
            hoursres:`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
            respons:formState.values.response,
            idusersres: usersfinal.id,
            state:1
        }
        const finalok = await API.put(`edit/requerimiento/${idpe}`, data);
        Swal.fire("Correcto!", 'Se cerro el ticket', "success");
        signIn();
        handleClose();
      } catch (err) {
          Swal.fire("Lo siento!", 'credencial invalida', "error");
      } 
    }
  };
  const handlePriory  = async  event =>  {
    event.preventDefault();
    if (formScala.values.escala === '') {
      Swal.fire("Lo siento!", 'Debes seleccionar la esclavilidad del requrimiento', "error");
    }else if (formScala.values.prioridad === '') {
      Swal.fire("Lo siento!", 'Debes seleccionar la prioridad del requerimiento', "error");
    }else if (formScala.values.nivel === '') {
      Swal.fire("Lo siento!", 'Debes seleccionar el nivel del requerimiento', "error");
    }else if (idpe === 0) {
      Swal.fire("Lo siento!", 'Debes seleccionar un requerimiento', "error");
    }  else {
      try {
        const data={
            escala:formScala.values.escala,
            prioridad:formScala.values.prioridad,
            nivel:formScala.values.nivel,
            idusersres: usersfinal.id,
        }
        const finalok = await API.put(`edit/requerimiento/pri/${idpe}`, data);
        Swal.fire("Correcto!", 'Se agrego perfectamente la prioridad', "success");
        console.log(finalok)
        signIn();
        handleClose();
      } catch (err) {
          Swal.fire("Lo siento!", 'credencial invalida', "error");
      } 
    }
  };
  const { signIn } = React.useContext(AuthContext);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [openuno, setOpenuno] = useState(false);
  const bodyfinal = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Agregar prioridad requerimiento</h2>
      <div className={classes.content} id="simple-modal-description">
        <div className={classes.contentBody} >
          <form
            className={classes.form}
            onSubmit={handlePriory}
          >
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="escala-native-simple">Selecciona la escalavilidad</InputLabel>
              <Select
                native
                value={formScala.values.escala || ''}
                onChange={handleChangeuno}
                name="escala"
                error={hasErroruno('escala')}
                fullWidth
                variant="outlined"
                inputProps={{
                  id: 'escala-native-required',
                }}
              >
                <option value=""></option>
                <option value={1}>Técnico No. 1</option>
                <option value={2}>Técnico No. 2</option>
                <option value={3}>Profesional</option>
                <option value={4}>Externo</option>
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="prioridad-native-simple">Selecciona la prioridad</InputLabel>
              <Select
                native
                value={formScala.values.prioridad || ''}
                onChange={handleChangeuno}
                name="prioridad"
                error={hasErroruno('prioridad')}
                fullWidth
                variant="outlined"
                inputProps={{
                  id: 'prioridad-native-required',
                }}
              >
                <option value=""></option>
                <option value={1}>Urgente</option>
                <option value={2}>Impacto</option>
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
            {console.log(formScala)}
            {formScala.values.prioridad === "1" ?
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="nivel-native-simple">Selecciona el nivel</InputLabel>
                <Select
                  native
                  value={formScala.values.nivel || ''}
                  onChange={handleChangeuno}
                  name="nivel"
                  error={hasErroruno('nivel')}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    id: 'nivel-native-required',
                  }}
                >
                  <option value=""></option>
                  <option value={1}>Muy Alto</option>
                  <option value={2}>Alto</option>
                  <option value={3}>Media</option>
                  <option value={4}>Bajo</option>
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>:null
            }
            {formScala.values.prioridad === "2" ?
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="nivel-native-simple">Selecciona el nivel</InputLabel>
                <Select
                  native
                  value={formScala.values.nivel || ''}
                  onChange={handleChangeuno}
                  name="nivel"
                  error={hasErroruno('nivel')}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    id: 'nivel-native-required',
                  }}
                >
                  <option value="">Selecciona el nivel </option>
                  <option value={1}>Extenso</option>
                  <option value={2}>Significativo</option>
                  <option value={3}>Moderado</option>
                  <option value={4}>Menor</option>
                </Select>
                <FormHelperText>Required</FormHelperText>
            </FormControl>:null}
          <Button
            className={classes.signInButton}
            color="primary"
            disabled={!formScala.isValid}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Responder
          </Button>
        </form>
      </div>
    </div>
      
    </div>
  );
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Resolver requerimiento</h2>
      <div className={classes.content} id="simple-modal-description">
        <div className={classes.contentBody} >
          <form
            className={classes.form}
            onSubmit={handleSignIn}
          >
          <TextField
            className={classes.textField}
            error={hasError('response')}
            fullWidth
            helperText={
              hasError('response') ? formState.errors.response[0] : null
            }
            label="Descripción"
            name="response"
            multiline
            rows={10}
            onChange={handleChange}
            type="text"
            value={formState.values.response || ''}
            variant="outlined"
          />
          <Button
            className={classes.signInButton}
            color="primary"
            disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Responder
          </Button>
        </form>
      </div>
    </div>
      
    </div>
  );
  const handleOpen = (item, id) => {
    console.log(item)
    setIdpe(item.id);
    if (id === 1) {
      setOpen(true);
    } else {
      setOpenuno(true);
    }
    
   
  };
  const handleClose = () => {
    setOpen(false);
    setOpenuno(false);
  };
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No. Ticket</TableCell>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Petición</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Hora</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(0, rowsPerPage).map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{user.code}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{user.nombre} {user.lastName}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.name}
                    </TableCell>
                    <TableCell>{user.description}</TableCell>
                    <TableCell>
                      {user.hours}
                    </TableCell>
                    <TableCell>
                      {user.date}
                    </TableCell>
                    <TableCell>
                      {user.tipo}
                    </TableCell>
                    <TableCell>
                      {user.state == 0 ? <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={()=>handleOpen(user, 2)}
                      >
                        <PlaylistAddSharpIcon/>
                      </Button>:
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                      >
                        <BlockSharpIcon/>
                      </Button>
                      }
                    </TableCell>
                    <TableCell>
                    {user.state == 0 ? <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={()=>handleOpen(user, 1)}
                      >
                        <CancelSharpIcon/>
                      </Button>: null
                      
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={users.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Modal
        open={openuno}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyfinal}
      </Modal>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
