import React, { useState, useEffect } from 'react';
import { Link as RouterLink , useHistory} from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import API from '../../../services/config';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button, Grid, Input, TextareaAutosize , FormControlLabel, Checkbox} from '@material-ui/core';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import {getvalidar} from '../../../action/Auth';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
import BlockSharpIcon from '@material-ui/icons/BlockSharp';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  label:{
    width:'100%',
  },
  actions: {
    justifyContent: 'flex-end'
  },
}));
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const NewInventory = () => {
  const cerrar = useHistory();
  const classes = useStyles();
  const users =JSON.parse(getvalidar('view'));
  const [state, setState] = useState({
    pc: users.pc === 1 ? true : false,
    port: users.port === 1 ? true : false,
    mouse: users.mouse === 1 ? true : false,
    tecl: users.tecl === 1 ? true : false,
    monitor: users.mont === 1 ? true : false,
  });
  const [art, setArt] = useState([]);
  const [help, setHelp] = useState([]);
  const { register, control, handleSubmit , watch, errors} = useForm();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const oficinalfinal  = async() =>  {
    try {
      const finalok = await API.get(`inventario/get/${users.id}`);
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  const helpdeskfinal  = async() =>  {
    try {
      const finalok = await API.get(`list/service/${users.idusers}`);
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  const handlePageChange = (event, page) => {
    setPage(page);
  };
  
  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  useEffect(() => {
    if (users.id) {
      oficinalfinal().then((data)=>{
        setArt(data);
      });
    }
    
  }, []);
  useEffect(() => {
    if (users.id) {
      helpdeskfinal().then((data)=>{
        setHelp(data)
      })
    }
    
  }, []);

  return (
      <div className={classes.root}>
        <div className={classes.content}>
        <div className={classes.root}>
            <form className="formfinal">
                <Grid
                container
                spacing={2}
                >
                  <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                        >
                          <label className="titulofinal">Inventario</label>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <label className={classes.label}>Selecciona la oficina</label>
                    <Controller
                      name="oficina"
                      as={Select}
                      options={[
                        { value: users.idofi, label: users.name },
                      ]}
                      control={control}
                      defaultValue={users.idofi}
                      required
                      disabled
                      />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <label className={classes.label}>Selecciona el responsable</label>
                    <Controller
                      name="responsable"
                      as={Select}
                      options={[
                        { value: users.idusers, label: `${users.nombre} ${users.lastName}` },
                      ]}
                      control={control}
                      defaultValue={users.idusers}
                      rules={{ required: true }}
                      required
                      disabled
                      />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <label className={classes.label}>Selecciona los equipo asignados</label>
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.pc} onChange={handleChange} name="pc" />}
                      label="Computador de escritorio" disabled
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.port} onChange={handleChange} name="port" />}
                      label="Portatil" disabled
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.mouse} onChange={handleChange} name="mouse" />}
                      label="Mouse" disabled
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.tecl} onChange={handleChange} name="tecl" />}
                      label="Teclado" disabled
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={state.monitor} onChange={handleChange} name="monitor" />}
                      label="Monitor" disabled
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <label className={classes.label}>Descripcion inventario</label>
                    <TextareaAutosize 
                    name="textarea"
                    aria-label="minimum height" 
                    rowsMin={3} placeholder="Minimum 3 rows" 
                    defaultValue={
                      users.description
                    }
                    />
                  </Grid>
                    {
                      art.map(element =>(
                        <Grid
                        container
                        className="ajutscont"
                        spacing={2}
                        key={element.id}
                        >
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                          >
                            <label className={classes.label}>
                              {
                                element.tipo === 1 ? <p>Computador</p>:null
                              }
                              {
                                element.tipo === 2 ? <p>Portatil</p>:null
                              }
                              {
                                element.tipo === 3 ? <p>Mouse</p>:null
                              }
                              {
                                element.tipo === 4 ? <p>Teclado</p>:null
                              }
                              {
                                element.tipo === 5 ? <p>Monitor</p>:null
                              }
                            </label>
                            </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xl={4}
                            xs={12}
                          >
                          <label className={classes.label}>No. referencia</label>
                            <input
                                name={`referencia${element.id}`}
                                value={element.refe}
                                disabled
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xl={4}
                            xs={12}
                          >
                          <label className={classes.label}>Marca</label>
                            <input
                                name={`marca${element.id}`}
                                value={element.marca}
                                disabled
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xl={4}
                            xs={12}
                          >
                          <label className={classes.label}>Modelo</label>
                            <input
                                name={`modelo${element.id}`}
                                value={element.modelo}
                                disabled
                            />
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                          >
                          <label className={classes.label}>Descripcion</label>
                          <TextareaAutosize 
                            name={`descript${element.id}`}
                            aria-label="minimum height" 
                            rowsMin={3} placeholder="Minimum 3 rows" 
                            defaultValue={
                              element.description
                            }
                          />
                          </Grid>
                        </Grid>
                      ))
                    }
                </Grid>
            </form>
            <CardContent className={[classes.content, 'tableajust']}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <h1>Listado de requerimiengos</h1>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell>No. Ticket</TableCell>
                        <TableCell>Usuario</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Petición</TableCell>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Hora</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Estado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {help.slice(0, rowsPerPage).map(user => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={user.id}
                          selected={selectedUsers.indexOf(user.id) !== -1}
                        >
                          <TableCell>
                            <div className={classes.nameContainer}>
                              <Typography variant="body1">{user.code}</Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.nameContainer}>
                              <Typography variant="body1">{user.nombre} {user.lastName}</Typography>
                            </div>
                          </TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>
                            {user.name}
                          </TableCell>
                          <TableCell>{user.description}</TableCell>
                          <TableCell>
                            {user.hours}
                          </TableCell>
                          <TableCell>
                            {user.date}
                          </TableCell>
                          <TableCell>
                            {user.tipo}
                          </TableCell>
                          <TableCell>
                            {user.state == 0 ? <Button
                              variant="contained"
                              color="secondary"
                              className={classes.button}
                            >
                              <PlaylistAddSharpIcon/>
                            </Button>:
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.button}
                            >
                              <BlockSharpIcon/>
                            </Button>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={[classes.actions, 'tableajust']}>
              <TablePagination
                component="div"
                count={help.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </CardActions>
        </div>
        </div>
      </div>
  );
};

export default NewInventory;
