import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    CardHeader,
    CardContent,
    Button,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { StatusBullet } from 'components';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    content: {
        padding: 0
    },
    inner: {
        minWidth: 800
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    status: {
        marginRight: theme.spacing(1)
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const statusColors = {
    delivered: 'success',
    pending: 'info',
    refunded: 'danger'
};

const LatestOrders = props => {
    const { className, order, ...rest } = props;
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const classes = useStyles();

    return ( <
        Card {...rest }
        className = { clsx(classes.root, className) } >
        <
        CardHeader title = "Listado de requerimientos" /
        >
        <
        Divider / >
        <
        CardContent className = { classes.content } >
        <
        PerfectScrollbar >
        <
        div className = { classes.inner } >
        <
        Table >
        <
        TableHead >
        <
        TableRow >
        <
        TableCell > Usuario < /TableCell> <
        TableCell > Email < /TableCell> <
        TableCell > Petición < /TableCell> <
        TableCell > Descripción < /TableCell> <
        TableCell > Hora < /TableCell> <
        TableCell > Fecha < /TableCell> <
        TableCell > Tipo < /TableCell> <
        TableCell > Estado < /TableCell> <
        /TableRow> <
        /TableHead> <
        TableBody > {
            order.slice(0, rowsPerPage).map(user => ( <
                TableRow className = { classes.tableRow }
                hover key = { user.id }
                selected = { selectedUsers.indexOf(user.id) !== -1 } >
                <
                TableCell >
                <
                div className = { classes.nameContainer } >
                <
                Typography variant = "body1" > { user.nombre } { user.lastName } < /Typography> <
                /div> <
                /TableCell> <
                TableCell > { user.email } < /TableCell> <
                TableCell > { user.name } <
                /TableCell> <
                TableCell > { user.description } < /TableCell> <
                TableCell > { user.hours } <
                /TableCell> <
                TableCell > { user.date } <
                /TableCell> <
                TableCell > { user.tipo } <
                /TableCell> <
                TableCell > {
                    user.state === 0 ? < StatusBullet
                    className = { classes.status }
                    color = { statusColors.refunded }
                    size = "sm" / > :
                        <
                        StatusBullet
                    className = { classes.status }
                    color = { statusColors.delivered }
                    size = "sm" / >
                } { user.state === 0 ? 'Pendiente' : 'Cerrado' } <
                /TableCell> <
                /TableRow>
            ))
        } <
        /TableBody> <
        /Table> <
        /div> <
        /PerfectScrollbar> <
        /CardContent> <
        Divider / >
        <
        CardActions className = { classes.actions } >
        <
        Button color = "primary"
        size = "small"
        variant = "text" >
        View all < ArrowRightIcon / >
        <
        /Button> <
        /CardActions> <
        /Card>
    );
};

LatestOrders.propTypes = {
    className: PropTypes.string,
    order: PropTypes.array.isRequired
};

export default LatestOrders;