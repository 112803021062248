import axios from 'axios';
import {getoken} from '../action/Auth';
const API = axios.create({
  baseURL: "https://systemresolutiondesarrollo.softsaenz.com.co/api/",
});
const validar = getoken();
API.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
if (validar !== '') {
  API.defaults.headers.common['Authorization'] = `Bearer ${validar}`
}


export default API;