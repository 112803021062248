import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import FingerprintSharpIcon from '@material-ui/icons/FingerprintSharp';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import BlockSharpIcon from '@material-ui/icons/BlockSharp';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import {validar, getvalidar} from '../../../../action/Auth';
import { Link as RouterLink , useHistory} from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
} from '@material-ui/core';
import Swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
  content: {
    width:'100%',
    padding: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    margin: theme.spacing(1),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    width:'100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom:5,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth:'100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const UsersTable = props => {
  const { className, users, ...rest } = props;
  const date = new Date();
  const classes = useStyles();
  const cerrar = useHistory();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  const view = (item, id)=>{
    if (id === 1) {
      
    }else{
      
      validar(item, 'view');
      cerrar.push('/inventory/view');
    }
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No. Inventario</TableCell>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Equipos</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(0, rowsPerPage).map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{user.code}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{user.nombre} {user.lastName}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.pc == 1 ?
                      <p>Computador de escritorio<br /></p>
                      :null}
                      {user.port === 1 ?
                      <p>Portatil<br /></p>
                      :null}
                      {user.mouse === 1 ?
                      <p>Mouse<br /></p>
                      :null}
                      {user.tecl === 1 ?
                      <p>Teclado<br /></p>
                      :null}
                      {user.mont === 1 ?
                      <p>Monitor<br /></p>
                      :null}
                    </TableCell>
                    <TableCell>
                      {user.state === 0 ? <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                      >
                        <FingerprintSharpIcon/>
                      </Button>:
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                      >
                        <BlockSharpIcon/>
                      </Button>
                      }
                    </TableCell>
                    <TableCell>
                     <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={()=>view(user, 1)}
                      >
                        <EditSharpIcon/>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={()=>view(user, 2)}
                      >
                        <VisibilitySharpIcon/>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={users.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
