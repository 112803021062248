import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import API from '../../../../services/config';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
const useStyles = makeStyles(() => ({
  root: {},
  textField:{
    width: '100%',
  }
}));

const AccountDetails = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const values = [];
  const [inicial, setInicial] = useState("");
  const [resp, setResp] = useState([]);
  const [final, setFinal] = useState("");
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
  }
  const handleChange = event => {
    console.log(event.target.value);
    setInicial(event.target.value);
  };
  const handleChange1 = event => {
    console.log(event.target.value);
    setFinal(event.target.value);
    //final = 
  };
  const handleSignIn  = async  () =>  {
    if (inicial === '') {
      Swal.fire("Lo siento!", 'Debes colocar una fecha inicial', "error");
    } else if (final === '') {
      Swal.fire("Lo siento!", 'Debes colocar una fecha final', "error");
    } else {
      try {
        Swal.fire({
          title: 'loading...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 5000,
        });
        const data={
          inicial: inicial,
          final: final
        }
        const finalok = await API.post('busqueda', data);
        if (finalok.data.length !==0) {
          finalok.data.forEach(element => {
            values.push({
              "No": element.id,
              "Codigo": element.code,
              "Nombres": element.nombre +' '+element.lastName,
              "Correo": element.email,
              "Requerimiento": element.name,
              "Descripcion": element.description,
              "Estado": element.state === 0 ? 'Activo' : 'Inactivo',
              "Fecha de creacion": element.date,
              "Hora de creacion": element.hours,
              "Tecnico asignado": element.tecnico+' '+element.ape,
              "Fecha de respuesta": element.dateres,
              "Hora de respuesta": element.hoursres,
              "Respuesta": element.respons,
              "Escala": element.escala === 1 ? 'Muy Alto' : element.escala === 2 ? 'Alto' : element.escala === 3 ? 'Media': element.escala === 4 ? 'Bajo':null,
              "prioridad": element.prioridad === 1 ? 'Extenso' : element.prioridad === 2 ? 'Significativo' : element.prioridad === 3 ? 'Moderado': element.prioridad === 4 ? 'Menor':null,
              "nivel": element.nivel === 1 ? 'Muy Alto' : element.nivel === 2 ? 'Alto' : element.nivel === 3 ? 'Media': element.nivel === 4 ? 'Bajo':null,
              "solution": element.solution === 0 ? 'No' : 'Si',
              "Fecha del registro": element.created_at,
            })
          });
          Swal.fire({ 
            title: 'Corecto!',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false
          });
          console.log(values);
          setResp(values)
        }else{
          Swal.fire("Lo siento!", 'No hay datos', "error");
        }
      } catch (err) {
          console.log(err)
          Swal.fire("Lo siento!", 'error en el servidor', "error");
      } 
    }
  };
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="Descargar reporte"
          title="Requerimientos"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                id="datetime"
                label="Fecha inicial"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                name="inicial"
                onChange={handleChange}
                value={inicial || '2020-07-24'}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                id="datetime-local"
                label="Fecha final"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                name="final"
                onChange={handleChange1}
                value={final || '2020-07-24'}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSignIn()}
          >
            Buscar
          </Button>
          {
            resp.length !== 0 ? <Button variant="warning" onClick={() => exportToCSV(resp,'data')}>Descargar</Button>: 'hola'
          }
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
