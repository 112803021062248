import React, { useState, useEffect } from 'react';
import { Link as RouterLink , useHistory} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Typography, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import {logout} from '../../../../action/Auth';
import API from '../../../../services/config';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className,history, onSidebarOpen, ...rest } = props;
  const [orders, setOrders] = useState([]);
  const cerrar = useHistory();
  const classes = useStyles();
  const handleSignIn  = async() =>  {
    try {
      const finalok = await API.get('active');
      return finalok.data;
    } catch (err) {
        console.log(err);
    } 
  }
  useEffect(() => {
    handleSignIn().then((data)=>setOrders(data));
  }, []);
  const [notifications, setNotifications] = useState({
    count:0,
  });
  const logueo = () => {
    //logout()
    
  }
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      {console.log(orders)}
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo_login.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Listado de peticiones No. {orders.length}</Typography>
                  {orders.map(device => (
                    <React.Fragment
                    key={device.id}>
                      <p><em>{device.name}</em></p> 
                      <p><b>{device.tipo}</b></p> 
                    </React.Fragment>
                  ))}
                </React.Fragment>
              }
            >
            <Badge
              badgeContent={orders.length}
              color="error"
              variant="dot"
              onClick={()=>{
                cerrar.push('/request');
              }}
            >
              <NotificationsIcon />
            </Badge>
            </HtmlTooltip>
          </IconButton>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={()=>{
              logout()
              cerrar.push('/');
            }}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  history: PropTypes.object
};

export default Topbar;
